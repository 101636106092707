import React from 'react'
import Reactmarkdown from 'react-markdown'
import { graphql } from 'gatsby'
import classes from './PolicyPage.module.scss'
import Layout from '../../../hoc/layout/layout'
import SEO from '../../../hoc/seo'

export const query = graphql`
  query($title: String!) {
    directusPolicy(title: { eq: $title }) {
      title
      policy_details
    }
  }
`
const PolicyPage = props => {
  return (
    <Layout>
      <SEO title={props.data.directusPolicy.title} />
      <div className={classes.policyContainer}>
        <h3>{props.data.directusPolicy.title}</h3>
        <div className={classes.detailsBox}>
          <Reactmarkdown source={props.data.directusPolicy.policy_details} />
        </div>
      </div>
    </Layout>
  )
}

export default PolicyPage
